.SecurityPolicy__main-container{
    margin-top: 8.9em;
    margin-left: 9em;
    margin-right: 9em;
    margin-bottom: 3em;
    /* display: flex;
    flex-direction: column;
    align-items: center; */

}
    .SecurityPolicy__title{
        text-align: center;
    }
    .SecurityPolicy__update{

    }

    .SecurityPolicy__parr{

    }
        .SecurityPolicy__parr p{
            font-family: 'Montserrat', sans-serif;
            font-weight: 800;
        }

    .SecurityPolicy__ul{

    }
        .SecurityPolicy__ul li{ 
            font-family: 'Montserrat', sans-serif;
        }


.SecurityPolicy__component{
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
}