.PrivacyTerms__main-section-container{
    color: #000;
    font-family: Lato;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;
}

.PrivacyTerms__title{
    text-decoration: underline;
}
.PrivacyTerms__customer-service{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
}

.PrivacyTerms__main-title{
    color:  #0D6782;
    text-align: center;
    font-family: Roboto Flex;
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1.3em;

}
.PrivacyTerms__description{
    color: #000;
    font-family: Lato;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.6rem; 
}
.PrivacyTerms__list li {
    margin-top:1em ;
    margin-bottom: 1em;
}

.PrivacyTerms__description ul li {
    word-wrap: break-word;
}
@media (max-width: 575.98px) {
    .PrivacyTerms__main-section-container{
        padding: 2em;
    }
}
        
        
@media (min-width: 576px) and (max-width: 767.98px) {
    .PrivacyTerms__main-section-container{
        padding: 2em;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .PrivacyTerms__main-section-container{
        padding: 3em;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .PrivacyTerms__main-section-container{
        padding: 4em;
    }
}

@media (min-width: 1200px) {
    .PrivacyTerms__customer-service-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;
        padding-bottom: 3em;
    }
    .PrivacyTerms__customer-service{
        width: 50%;
    }
    .PrivacyTerms__main-section-container{
        padding: 2.5em;
    }
}