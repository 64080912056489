
.SubscriptionPlan__main-section-container{
    padding: 0;

}
    .SubscriptionPlan__title-container{

    }
        .SubscriptionPlan__title{
            color:  #0D6782;
            font-family: Roboto Flex;
            font-size: 3.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    .SubscriptionPlan__subscription-plans-container{
        display: flex;
    }

  







@media (max-width: 575.98px) {
    
.SubscriptionPlan__main-section-container{
    padding: 1.3em;

}
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .SubscriptionPlan__title{
        line-height: 2.5rem; 
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .SubscriptionPlan__main-section-container{
        padding: 1.3em;
    }
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .SubscriptionPlan__title{
        line-height: 2.5rem; 
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SubscriptionPlan__main-section-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: row;
        justify-content: center;
        margin-left: 1.8em;
        padding: 1em;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: row;
        justify-content: space-between;
        padding: 2em;
        /* border: 3px solid red; */
    }
    .SubscriptionPlan__subscription-description-component-container{
        /* border: 3px solid blue; */
        margin-right: 1em;
        margin-left: 1em;
    }
    .SubscriptionPlan__subscription-card-component-container{
        /* border: 8px solid green; */
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 1em;
        margin-left: 1em;
    }

}

@media (min-width: 1200px) {
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: row;
        justify-content: center;
        align-content: center;
        padding: 6em;
    }
}